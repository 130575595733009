import React, {useEffect, useState} from 'react';
import {Script} from 'gatsby';
import {Helmet} from 'react-helmet';
import HeadingBanner from '../Shared/HeadingBanner/HeadingBanner';
import OurHelpCard from './OurHelp/OurHelpCard';
import TechnicalExpertise from './TechnicalExpertise';
import TechnicalServices from './TechnicalServices';
import DropDownProjectFilter from './DropdownExpertiseFilter/DropDownExpertiseFilter';
import ExpertiseFilter from './ExpertiseFilter/ExpertiseFilter';
import ClientTestimonialCarousal from './ClientTestimonialCarousel/ClientTestimonialCarousel';
import '../../assets/css/expertise.css';

const SERVICES_EXPERTISE_DATA = [
  {
    id: 'Web',
    category: 'Web',
    services: [
      'Real-time applications',
      'Serverless applications',
      'Data analysis',
      'Data visualisation',
      'Cloud infrastructure',
      'CI/CD',
      'Docker',
      'Payment gateway integrations',
      'Microservices',
      'Database design & management',
      'Web security',
      'Cloud hosting & deployment',
    ],
    expertise: [
      {
        label: 'Python',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/python.svg')
          .default,
      },
      {
        label: 'Node JS',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/nodejs.svg')
          .default,
      },
      {
        label: 'ReactJS',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/react.webp')
          .default,
      },
      {
        label: 'Angular',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/angular.svg')
          .default,
      },
      {
        label: 'Devops',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/devops.svg')
          .default,
      },
      {
        label: 'Go',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/go.svg').default,
      },
      {
        label: 'Ruby on Rails',
        iconSrc:
          require('../../assets/images/expertise/technical-expertise/webdev/ruby-on-rails.svg')
            .default,
      },
      {
        label: 'Java',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/java.svg')
          .default,
      },
      {
        label: 'Shopify',
        iconSrc: require('../../assets/images/expertise/technical-expertise/webdev/shopify.svg')
          .default,
      },
    ],
  },
  {
    id: 'Mobile',
    category: 'Mobile',
    services: [
      'Reactive programming',
      'Architecture design patterns',
      'Analytics',
      'Dependency injection',
      'Mobile backend engineering',
      'Scalability & interoperability',
    ],
    expertise: [
      {
        label: 'React Native',
        iconSrc:
          require('../../assets/images/expertise/technical-expertise/mobiledev/react-native.svg')
            .default,
      },
      {
        label: 'Android',
        iconSrc: require('../../assets/images/expertise/technical-expertise/mobiledev/android.svg')
          .default,
      },
      {
        label: 'iOS',
        iconSrc: require('../../assets/images/expertise/technical-expertise/mobiledev/ios.svg')
          .default,
      },
    ],
  },
  {
    id: 'Blockchain',
    category: 'Blockchain',
    services: [
      'DApps development',
      'Crypto asset integration',
      'Metaverse gaming',
      'Fungible tokens',
      'DeFi',
      'NFTs',
      'Crypto wallets',
      'Crypto exchange',
      'Clarity contract audits',
      'GameFi',
      'ICOs',
    ],
    expertise: [
      {
        label: 'Clarity',
        iconSrc: require('../../assets/images/expertise/technical-expertise/blockchain/clarity.svg')
          .default,
      },
      {
        label: 'Solidity',
        iconSrc:
          require('../../assets/images/expertise/technical-expertise/blockchain/solidity.svg')
            .default,
      },
      {
        label: 'Rosetta',
        iconSrc: require('../../assets/images/expertise/technical-expertise/blockchain/rosetta.svg')
          .default,
      },
    ],
  },
  {
    id: 'IoT',
    category: 'IoT',
    services: [
      'Hardware design',
      'Firmware and BSP',
      'Prototype manufacturing',
      'Cloud (AWS / GCP)',
      'Analytics',
    ],
    expertise: [
      {
        label: 'Esspressive',
        iconSrc: require('../../assets/images/expertise/technical-expertise/iot/esspressive.svg')
          .default,
      },
      {
        label: 'Arm',
        iconSrc: require('../../assets/images/expertise/technical-expertise/iot/arm.svg').default,
      },
      {
        label: 'Altium Designer',
        iconSrc:
          require('../../assets/images/expertise/technical-expertise/iot/altium-designer.svg')
            .default,
      },
      {
        label: 'Arduino',
        iconSrc: require('../../assets/images/expertise/technical-expertise/iot/arduino.svg')
          .default,
      },
      {
        label: 'Nordic',
        iconSrc: require('../../assets/images/expertise/technical-expertise/iot/nordic.webp')
          .default,
      },
      {
        label: 'Analog Devices',
        iconSrc: require('../../assets/images/expertise/technical-expertise/iot/analog-devices.svg')
          .default,
      },
    ],
  },
  {
    id: 'Games',
    category: 'Games',
    services: [
      'Game design',
      'Game art & UI',
      'Metaverse gaming',
      'User flow mapping',
      'Avatar animations',
      'NFT marketplace',
      'Game economy setup',
      'Game loop creation',
      '3D avatars creation',
    ],
    expertise: [
      {
        label: 'Unity3D',
        iconSrc: require('../../assets/images/expertise/technical-expertise/games/unity3d.svg')
          .default,
      },
      {
        label: 'Three.js',
        iconSrc: require('../../assets/images/expertise/technical-expertise/games/threejs.svg')
          .default,
      },
      {
        label: 'Phaser',
        iconSrc: require('../../assets/images/expertise/technical-expertise/games/phaser.svg')
          .default,
      },
    ],
  },
  {
    id: 'QA',
    category: 'QA',
    services: [
      'Manual testing',
      'Automation testing',
      'CI/CD',
      'Performance testing',
      'API testing',
      'System testing',
      'Case design',
      'Test management ',
      'Defect tracking ',
      'Integration testing ',
    ],
    expertise: [
      {
        label: 'Selenium',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/selenium.svg')
          .default,
      },
      {
        label: 'Appium',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/appium.svg').default,
      },
      {
        label: 'TestNG',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/test-ng.svg')
          .default,
      },
      {
        label: 'JUnit',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/j-unit.svg').default,
      },
      {
        label: 'NUnit',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/n-unit.svg').default,
      },

      {
        label: 'Postman',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/postman.svg')
          .default,
      },
      {
        label: 'Soap UI',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/soap-ui.svg')
          .default,
      },
      {
        label: 'Apache',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/apache.svg').default,
      },
      {
        label: 'REST-Assured',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/rest-assured.svg')
          .default,
      },
      {
        label: 'Test Rail',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/test-rail.svg')
          .default,
      },
      {
        label: 'Pytest',
        iconSrc: require('../../assets/images/expertise/technical-expertise/qa/py-test.svg')
          .default,
      },
    ],
  },
  {
    id: 'Design',
    category: 'Design',
    services: [
      'Product discovery',
      'UX/UI design',
      'Interactive prototypes',
      'Design systems',
      'Branding services',
      'Game design',
      'Product gamification',
      'Usability audits',
      'WCAG compliance',
    ],
    expertise: [
      {
        label: 'AdobeXD',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/adobe-xd.svg')
          .default,
      },
      {
        label: 'Figma',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/figma.svg')
          .default,
      },
      {
        label: 'Adobe Illustrator',
        iconSrc:
          require('../../assets/images/expertise/technical-expertise/design/adobe-illustrator.svg')
            .default,
      },
      {
        label: 'Adobe Photoshop',
        iconSrc:
          require('../../assets/images/expertise/technical-expertise/design/adobe-photoshop.svg')
            .default,
      },
      {
        label: 'Miro',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/miro.svg')
          .default,
      },
      {
        label: 'Zeplin',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/zeplin.svg')
          .default,
      },
      {
        label: 'Framer',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/framer.svg')
          .default,
      },
      {
        label: 'Sketch',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/sketch.svg')
          .default,
      },
      {
        label: 'Moqups',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/moqups.svg')
          .default,
      },
      {
        label: ' Balsamiq',
        iconSrc: require('../../assets/images/expertise/technical-expertise/design/balsamiq.svg')
          .default,
      },
    ],
  },
];

const CLIENT_TESTIMONIAL_DATA = [
  {
    category: 'Web',
    review:
      'I am fond of the quality of their work and their willingness to be present as a team member.',
    name: 'Amra Tareen',
    designation: (
      <div>
        Head of Innovations - <strong>Bed, Bath & Beyond</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/amra-tareen.webp').default,
  },
  {
    category: 'Mobile',
    review: 'We could not have been so successful in the mobile market without them!',
    name: 'Jordan Mendell',
    designation: (
      <div>
        Head of Mobile & Social Projects - <strong>DraftKings</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/jordan-mendell.webp').default,
  },
  {
    category: 'Blockchain',
    review:
      'They have quality engineering, great communication, and are very committed to the project.',
    name: 'John Koelliker',
    designation: (
      <div>
        CCO - <strong>Stick Sports Ltd.</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/john-koelliker.webp').default,
  },
  {
    category: 'IoT',
    review:
      'They were flexible and adaptable to the problem and issues encountered, and very resourceful when dealing with issues.',
    name: 'Patrick Cosgrove',
    designation: (
      <div>
        CTO - <strong>Lifeprint</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/patrick-cosgrove.webp')
      .default,
  },
  {
    category: 'Games',
    review: '[They] are realistic in what they can do, and don’t oversell themselves.',
    name: 'Paul Collins',
    designation: (
      <div>
        CCO - <strong>Stick Sports Ltd.</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/paul-collins.webp').default,
  },
  {
    category: 'QA',
    review:
      'Tintash has been really helpful, and we wouldn’t have managed to do this project without them.',
    name: 'Kristin Anderssen',
    designation: (
      <div>
        Co-Founder & Chair - <strong>ConZultation AS</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/kristin-anderssen.webp')
      .default,
  },
  {
    category: 'Design',
    review:
      'They were flexible and adaptable to the problem and issues encountered, and very resourceful when dealing with issues.',
    name: 'Emily Fisher',
    designation: (
      <div>
        Head of Product & Design - <strong>Decorist</strong>
      </div>
    ),
    imgSrc: require('../../assets/images/expertise/client-testimonial/emily-fisher.webp').default,
  },
];
const categories = ['Mobile', 'Blockchain', 'IoT', 'Games', 'QA', 'Design'];

const Expertise = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryChange = category => {
    setSelectedCategory(category);
  };

  let filteredProjects = [];

  if (selectedCategory && selectedCategory !== 'Web') {
    filteredProjects = SERVICES_EXPERTISE_DATA.filter(
      project => project.category === selectedCategory
    );
  } else {
    filteredProjects = SERVICES_EXPERTISE_DATA.filter(project => project.category === 'Web');
  }
  useEffect(() => {
    // add widget to end of body and run it
    const script = document.createElement('script');
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    script.setAttribute('data-url', 'https://widget.clutch.co');
    script.setAttribute('data-widget-type', '4');
    script.setAttribute('data-height', 'auto');
    script.setAttribute('data-nofollow', 'true');
    script.setAttribute('data-expandifr', 'true');
    script.setAttribute('data-scale', '100');
    script.setAttribute('data-header-color', '#17313B');
    script.setAttribute('data-footer-color', '#17313B');
    script.setAttribute('data-scale', '100');
    script.setAttribute('data-primary-color', '#03A9F4');
    script.setAttribute('data-secondary-color', '#03A9F4');
    script.setAttribute('data-reviews', '2086941,2039625,2016135,1943547,1931141');
    script.setAttribute('data-clutchcompany-id', '26644');
    document.body.appendChild(script);

    script.onload = () => {
      window.CLUTCHCO.Init();
    };
    return () => {
      window.CLUTCHCO.Destroy();
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Expertise | Tintash</title>
        </Helmet>
        <div className="expertise-container">
          {/* Company Page Banner */}
          <div className="heading-banner-section">
            <HeadingBanner
              heading={
                <div>
                  Creating{' '}
                  <strong style={{color: '#1AA5F8'}}>
                    simple, functional, and meaningful products
                  </strong>{' '}
                  since 2008
                </div>
              }
              description={
                <div>
                  Building successful products requires expertise in domain, design, and
                  engineering. We bring the design and engineering know-how, while collaborating
                  with customers who have deep domain knowledge. Together, we create exceptional
                  solutions.
                </div>
              }
              imageSrc={require('../../assets/images/company/topbanner-bg-2.webp').default}
            />
          </div>
          <section className="technical-expertise-section">
            <h1 className="text-center main-heading">Services & Expertise</h1>
            <div className="container">
              <ExpertiseFilter
                categories={categories}
                selectedCategory={selectedCategory}
                onCategoryChange={handleCategoryChange}
              />
              <DropDownProjectFilter
                categories={categories}
                selectedCategory={selectedCategory}
                onCategoryChange={handleCategoryChange}
              />
            </div>

            {filteredProjects.map(card => (
              <TechnicalServices services={card.services} />
            ))}
            {filteredProjects.map(card => (
              <TechnicalExpertise expertise={card.expertise} />
            ))}
            <section className="client-testimonial-section py-5">
              <ClientTestimonialCarousal
                testimonials={CLIENT_TESTIMONIAL_DATA}
                selectedCategory={selectedCategory}
              />
            </section>
          </section>

          <section className="client-review-section text-center">
            <h1 className="main-heading">Third party client reviews</h1>
            <div className="client-review-container text-center">
              <div
                className="clutch-widget"
                data-url="https://widget.clutch.co"
                data-widget-type="4"
                data-height="auto"
                data-nofollow="true"
                data-expandifr="true"
                data-scale="100"
                data-header-color="#17313B"
                data-footer-color="#17313B"
                data-scale="100"
                data-primary-color="#03A9F4"
                data-secondary-color="#03A9F4"
                data-reviews="2086941,2039625,2016135,1943547,1931141"
                data-clutchcompany-id="26644"
              />
            </div>
          </section>
        </div>
      </>
    </>
  );
};

export default Expertise;
